import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div style={{display:"flex", width:"100%", height:"100vh", justifyContent:"center", alignContent:"center", justifyItems:"center", alignItems:"center" }}>
          <img style={{ width:"125px", height:"125px", resize:"both "}} src={"/img/logo/flicky.png"} alt="logo" />
        </div>
      </header>
    </div>
  );
}

export default App;
